<template>
  <form class="max-w-5xl">
    <div class="mt-10 mb-40 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" :class="{'opacity-30 disabled': program.deletedAt}">
      <form-input v-model="program.name" label="Name" class="md:col-span-4" :error-msg="result.name.$messages.join(' | ')" />

      <form-input v-model="program.automationAppId" label="Automation App Id" type="number" :range="[0, 9999]" class="md:col-span-4" :error-msg="result.automationAppId.$messages.join(' | ')" />

      <form-input v-model="program.description" label="Description" class="md:col-span-4" :error-msg="result.description.$messages.join(' | ')" />

      <form-textarea v-model="program.info" label="Info" class="col-span-full" />

      <form-radio-group
        v-model="program.licenseType"
        label="License type"
        :items="[
          { name: 'Free', value: 'free' },
          { name: 'Paid', value: 'paid' }
        ]"
        :error-msg="result.licenseType.$messages.join(' | ')"
        class="col-span-full"
      />

      <form-select
        v-model="program.developerId"
        label="Developer"
        placeholder="Select a developer"
        :items="stats?.developers"
        :error-msg="result.developerId.$messages.join(' | ')"
        class="col-span-4"
      >
        <template #icon>
          <PlusIcon class="size-5 ml-1 cursor-pointer" @click="openDialog('developer')" />
        </template>
      </form-select>

      <form-select
        v-model="program.publisherId"
        label="Publisher"
        placeholder="Select a publisher"
        :items="stats?.publishers"
        :error-msg="result.publisherId.$messages.join(' | ')"
        class="col-span-4"
      >
        <template #icon>
          <PlusIcon class="size-5 ml-1 cursor-pointer" @click="openDialog('publisher')" />
        </template>
      </form-select>

      <form-select
        v-model="program.categoryId"
        label="Category"
        placeholder="Select a category"
        :items="stats?.categories"
        :error-msg="result.categoryId.$messages.join(' | ')"
        class="col-span-4"
      >
        <template #icon>
          <PlusIcon class="size-5 ml-1 cursor-pointer" @click="openDialog('category')" />
        </template>
      </form-select>

      <div class="col-span-2" />

      <form-image-upload v-model="iconUrlProxy" label="App Icon" class="col-span-2" :error-msg="result.iconLargeUrl.$messages.join(' | ')" />

      <form-image-upload v-model="program.imageUrl" label="Main image" class="col-span-2" :error-msg="result.imageUrl.$messages.join(' | ')" />

      <form-image-upload v-model="program.splashImageUrl" label="Splash image" class="col-span-2" :error-msg="result.splashImageUrl.$messages.join(' | ')" />

      <form-multiple-images-upload v-model="program.imageUrls" label="Images" class="col-span-full" :error-msg="result.imageUrls.$messages.join(' | ')" @main-image="handleMainImge" />
    </div>
  </form>
</template>

<script setup>
import { reactive, computed } from 'vue';
import FormInput from './FormInput.vue';
import FormTextarea from './FormTextarea.vue';
import FormRadioGroup from './FormRadioGroup.vue';
import FormSelect from './FormSelect.vue';
import FormImageUpload from './FormImageUpload.vue';
import FormMultipleImagesUpload from './FormMultipleImagesUpload.vue';
import ModalPrompt from './ModalPrompt.vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { getStats, reloadStats } from '../composables/useProgramStats';
import { Modal } from '../composables/useModal';
import useValidate from 'vue-tiny-validate';

const propNameByEntity = {
  developer: 'developerId',
  publisher: 'publisherId',
  category: 'categoryId'
};

const program = defineModel('program', {
  type: Object,
  default: () => ({
    name: '',
    description: '',
    info: '',
    licenseType: '',
    developerId: null,
    publisherId: null,
    categoryId: null,
    iconUrl: '',
    iconLargeUrl: '',
    imageUrl: '',
    imageUrls: []
  })
});

defineExpose({
  onValidate,
  getResult
});

const rules = reactive({
  name: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Name must not be empty.'
  },
  automationAppId: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Automation App Id must not be empty.'
  },
  description: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Description must not be empty.'
  },
  licenseType: {
    name: 'required',
    test: value => Boolean(value),
    message: 'LicenseType must not be empty.'
  },
  developerId: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Developer must not be empty.'
  },
  publisherId: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Publisher must not be empty.'
  },
  categoryId: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Category must not be empty.'
  },
  iconLargeUrl: {
    name: 'required',
    test: value => Boolean(value),
    message: 'App icon must not be empty.'
  },
  imageUrl: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Main image must not be empty.'
  },
  splashImageUrl: {
    name: 'required',
    test: value => Boolean(value),
    message: 'Splash image must not be empty.'
  },
  imageUrls: [
    { name: 'required', test: value => Boolean(value) },
    {
      name: 'required',
      test: value => value,
      message: 'There must be a minimum of 1 image.'
    }
  ]
});
const options = reactive({
  autoTest: true
});
const { result } = useValidate(program, rules, options);

async function onValidate() {
  return await result.value.$test();
}

function getResult() {
  return result.value;
}

const iconUrlProxy = computed({
  get: () => program.value.iconLargeUrl,
  set: val => {
    program.value.iconLargeUrl = val;
    program.value.iconUrl = null;
  }
});

const stats = getStats();

const openDialog = async entity => {
  const newEntity = await Modal.dialog({
    title: `Add new ${entity}`,
    component: ModalPrompt,
    componentData: { label: 'Name', placeholder: 'Type name', iconColor: 'primary', entity }
  });

  if (!newEntity) {
    return;
  }

  const propName = propNameByEntity[entity];

  if (!propName) {
    console.log('Invalid entity name');
    return;
  }

  await reloadStats();

  program.value[propName] = newEntity;
};

const handleMainImge = newImg => {
  program.value.imageUrl = newImg;
};
</script>
